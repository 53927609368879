<template>
  <eden-container>
    <div class="wallet-banner">
      <div class="value text-bold">{{ formatPrice(wallet.balance) }}</div>
      <div class="information">Wallet Balance</div>
    </div>
    <div class="wallet-information">
      <div class="information-text">
        <el-table :data="walletInfo" :show-header="false">
          <el-table-column>
            <template slot-scope="scope">
              <span class="label font-xsm">{{ scope.row.label }}</span>
              <p v-if="scope.row.text" class="text">
                {{ formatPrice(scope.row.text) }}
              </p>
              <p v-else>-</p>
            </template>
          </el-table-column></el-table
        >
      </div>
    </div>
  </eden-container>
</template>

<script>
export default {
  props: {
    wallet: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    walletInfo() {
      return [
        {
          label: "Available to spend",
          text: `${this.wallet.available_to_spend}`,
        },
        {
          label: "Amount spent",
          text: `${this.wallet.amount_spent}`,
        },
        {
          label: "Amount owed",
          text: `${this.wallet.amount_owed}`,
        }, 
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 0;
  padding: 0;
}
.wallet-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;
  border-right: 1px solid #f0f4f2;

  .value {
    margin-bottom: 8px;
    font-size: 2.5rem;
  }
  .information {
    color: var(--eden-grey-tertiary);
  }
}

.wallet-information {
  .information-text {
    // border-bottom: 1px solid #f0f4f2;
    .label {
      color: var(--eden-grey-quaternary);
      font-weight: 400;
      font-size: 12px;
    }
    .text {
      font-size: 14px;
    }
  }
}
</style>
