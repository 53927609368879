<template>
  <div class="wallet-activity">
    <template>
      <eden-table-actions :title="title" :show-search="false">
        <template slot="actions">
          <el-row type="flex" justify="end" align="middle" :gutter="10">
            <el-col :span="10">
              <el-button
                type="plain"
                icon="eden-icon-upload"
                class="ml-10"
                @click="exportAction"
              >
                Export
              </el-button>
            </el-col>

            <el-col :span="10">
              <div class="is-flex is-justify-end">
                <wallets-filter
                  :disabled="loading"
                  :clear="filterParams.clear"
                  @filter="filter"
                />
              </div>
            </el-col>
            <el-col :span="10">
              <div class="is-flex is-justify-end">
                <eden-periods
                  :default-period="'thisweek'"
                  :custom-periods="periods"
                  :show-custom-period="true"
                  @set-period="getCustomerWallet"
                />
              </div>
            </el-col>
          </el-row>
        </template>
      </eden-table-actions>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <template v-if="pageData.length">
          <el-table :data="pageData">
            <el-table-column>
              <template #header>
                <eden-table-column-header
                  :label="'Amount'"
                  :property="'amount'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'amount')"
                />
              </template>
              <template v-slot="scope">
                {{ formatPrice(scope.row.amount) }}
              </template>
            </el-table-column>
            <el-table-column width="120" prop="plan">
              <template slot="header"> </template>
              <template slot-scope="scope">
                <div class="is-flex is-align-center">
                  <el-tag :type="setType(scope.row.type)">
                    {{ formatText(scope.row.type) }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template #header>
                <eden-table-column-header
                  :label="'Balance'"
                  :property="'total_balance'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'total_balance')"
                />
              </template>
              <template v-slot="scope">
                <span> {{ formatPrice(scope.row.total_balance) }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template #header>
                <eden-table-column-header
                  :label="'Admin'"
                  :property="'admin'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'admin')"
                />
              </template>
              <template v-slot="scope">
                <span> {{ formatText(scope.row.admin.name) }}</span>
              </template>
            </el-table-column>
            <el-table-column width="180">
              <template #header>
                <eden-table-column-header
                  :label="'Channel'"
                  :property="'channel'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'channel')"
                />
              </template>
              <template v-slot="scope">
                <span>
                  {{
                    formatText(
                      scope.row.channel === "receipt"
                        ? "Payment receipt"
                        : scope.row.channel,
                    )
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column>
              <template #header>
                <eden-table-column-header
                  :label="'Date'"
                  :property="'created_at'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'created_at')"
                />
              </template>
              <template v-slot="scope">
                <span> {{ formatDate(scope.row.created_at, "m do, y") }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template #header>
                <eden-table-column-header
                  :label="'Attachments'"
                  :property="'attachment'"
                  :sort-property="sort.property"
                  @sort="sortPageData($event, 'attachment')"
                />
              </template>
              <template v-slot="scope">
                <span
                  class="text-primary text-cursor text-underline"
                  v-if="scope.row.channel === 'receipt'"
                >
                  <span
                    class="text-primary text-cursor text-underline"
                    @click="viewAttachment(scope.row.attachment)"
                  >
                    Receipt.{{ scope.row.attachment.split(".").pop() }}
                  </span>
                  <span><i class="el-icon-paperclip"></i></span>
                </span>
                <span
                  class="text-primary text-cursor text-underline"
                  @click="viewNote(scope.row.reason)"
                  v-else-if="scope.row.reason"
                >
                  View note <span><i class="el-icon-chat-round"></i></span>
                </span>
                <span class="" v-else> - </span>
                <eden-confirm-dialog
                  title="Note"
                  button-text="Okay, got it!"
                  button-type="primary"
                  :show.sync="showSelectionWindow"
                  @confirm="changeNoteDialog"
                >
                  <el-form :model="form" label-position="top" ref="form">
                    <el-row type="flex">
                      <el-col :span="24">
                        <el-form-item label="Note" prop="selectedNote">
                          <el-input
                            type="textarea"
                            rows="4"
                            v-model="selectedNote"
                            readonly
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </eden-confirm-dialog>
              </template>
            </el-table-column>
          </el-table>
          <eden-pagination
            v-if="showPagination"
            :page-size="pagination.pageSize"
            :from="pagination.from"
            :to="pagination.to"
            :total="pagination.total"
            :current-page.sync="page"
          />
        </template>
        <eden-content-empty v-else :text="'No activity'" />
      </template>
    </template>
    <wallet-transactions-export
      :show.sync="showExportwallets"
      :data-to-export="exportData"
      :stringed-filter="stringedParams"
    />
  </div>
</template>

<script>
import WalletsFilter from "@/components/Customers/Corporate/Wallet/WalletsFilter";
import WalletTransactionsExport from "@/components/Customers/Corporate/Wallet/WalletTransactionsExport";
import wallet from "@/requests/customers/corporate/wallet";

export default {
  name: "WalletActivity",
  components: {
    WalletsFilter,
    WalletTransactionsExport,
  },
  props: {
  },
  data() {
    return {
      loading: false,
      pageData: [],
      form: {},
      showPagination: true,
      showExportwallets: false,
      period: "thisweek",
      exportData: [],
      page: 1,
      custom: {
        from: null,
        to: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
      periods: {
        // alltime: "All time",
        today: "Today",
        yesterday: "Yesterday",
        thisweek: "This week",
        lastweek: "Last week",
        thismonth: "This month",
      },
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      stringedParams: "",
      showSelectionWindow: false,
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      selectedNote: "",
    };
  },
  watch: {
    page() {
      this.getCustomerWallet({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    title() {
      const total = this.pagination.total;
      const result = this.pageData.length;
      const value = (this.showPagination ? total : result) || 0;
      return `${value} Transaction${value > 1 ? "s" : ""}`;
    },
  },
  created() {
    this.getCustomerWallet({ period: this.period });
  },
  methods: {
    getCustomerWallet({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.loading = true;
      let params = `page=${this.page}&corporation_id=${this.customerId}&start_date=${start_date}&end_date=${end_date}`;
      this.stringedParams = params;
      wallet
        .index(this.stringedParams)
        .then((response) => {
          const { status, meta, data } = response.data;
          if (status) {
            this.pageData = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;
            this.pagination.pageSize = meta.per_page;
            if (this.period === "custom") {
              this.custom.from = start_date;
              this.custom.to = end_date;
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    sortPageData(direction, property) {
      this.sort = { property, direction };
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
      });
      this.page = 1
      if (params.start_date && params.end_date) {
        start_date = params.start_date;
        end_date = params.end_date;
        delete params.start_date;
        delete params.end_date;
      }
      if (params.transaction_type === "all") {
        delete params.transaction_type;
      }
      const constParams = `page=${this.page}&corporation_id=${this.customerId}&start_date=${start_date}&end_date=${end_date}`;
      let stringedParams;
      if (Object.keys(params).length) {
        stringedParams = `${constParams}&${this.sortObjectAsParams(params)}`;
      } else {
        stringedParams = constParams;
      }
      this.stringedParams = stringedParams;
      wallet
        .index(this.stringedParams)
        .then((response) => {
          const { status, meta, data } = response.data;

          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };

            this.pageData = data;
            this.pagination.from = meta.from;
            this.pagination.to = meta.to;
            this.pagination.total = meta.total;
            this.pagination.pageSize = meta.per_page;
            this.loading = false;
            // this.showPagination = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.getCustomerWallet({ period: this.period });
      }
    },
    clearFilters() {
      this.getCustomerWallet({ period: this.period });
      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
    exportAction() {
      if (this.pageData.length) {
        this.exportData = this.pageData;
      } else {
        this.exportData = [];
      }
      this.showExportwallets = true;
    },
    viewAttachment(url) {
      window.open(url, "_blank");
    },
    viewNote(reason) {
      this.selectedNote = reason;
      this.showSelectionWindow = true;
    },
    changeNoteDialog() {
      this.showSelectionWindow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
