<template>
  <div>
    <el-dialog
      title="Export Wallet Transactions"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Proceed to select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportWalletTransactions"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>
  
  <script>
import wallet from "@/requests/customers/corporate/wallet";
export default {
  name: "WalletTransactionsExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    dataToExport: {
      type: Array,
      default() {
        return [];
      },
    },
    stringedFilter: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        format: "",
      },
      exportData: {},
      exporting: false,
      loading: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.shouldShow = false;
      this.exporting = false;
    },
    exportWalletTransactions() {
      this.mixpanelTrack({ event: "wallets_export" });
      this.loading = true;
      wallet
        .export(this.stringedFilter)
        .then((response) => {
          this.formatExportData(response.data.data);
          this.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response?.data.day;
          if (errorMessage && errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage?.message || "An error occurred.");
          }
          this.loading = false;
        });
    },
    formatExportData(data) {
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Wallet Transaction \nExported at - ${date}`;
      let walletData = [];
      let headers = {
        sn: "S/N",
        amount: "Amount",
        type: "Transaction Type",
        total_balance: "Balance",
        admin: "Admin",
        channel: "Channel",
        created_at: "Date",
      };
      walletData = data.map((wallet, index) => {
        return {
          sn: index + 1,
          amount: this.formatExportPrice(wallet.amount),
          type: this.formatText(wallet.type),
          channel: this.formatText(wallet.channel),
          admin: wallet.admin?.name,
          total_balance: this.formatExportPrice(wallet?.total_balance),
          created_at: this.formatDate(wallet.created_at, "m do, y"),
        };
      });

      this.exportData = {
        title,
        headers,
        data: walletData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
  <style lang="scss" scoped></style>
  