<template>
  <el-dialog
    title="Attach Payment receipt"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Total amount paid"
            prop="total_amount_paid"
            :rules="validateField()"
          >
            <el-input
              placeholder="Enter amount"
              type="text"
              v-number
              v-model.number="form.total_amount_paid"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <eden-attachments-drag-and-drop
            :attachments.sync="form.attachment"
            :file-folder="'payment-reciept'"
            ref="attachmentsComponent"
          />
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        type="primary"
        :disabled="!form.total_amount_paid || !form.attachment.length"
        :loading="loading"
        @click="attachReceipt"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>
  
  <script>
import wallet from "@/requests/customers/corporate/wallet";

export default {
  name: "PaymentReceiptAttachmentDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    acceptedFileTypes: {
      type: Array,
      default() {
        return [".jpeg", ".jpg", ".png", ".pdf", ".doc", ".docx", ".webp"];
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        total_amount_paid: 0,
        attachment: [],
        walletActivityData: [],
        walletSummaryData: {},
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
      this.$refs.attachmentsComponent.resetAttachments();
    },
    attachReceipt() {
      this.loading = true;
      const payload = {
        corporation_id: this.customerId,
        amount: parseFloat(this.form.total_amount_paid),
        channel: "receipt",
        attachment: this.form.attachment[0].url,
      };
      wallet
        .fundWallet(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("update-activity");
            this.loading = false;
            this.closeEvent();
            this.form = {};
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.data) {
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          } else {
            this.$message.error("An unexpected error occurred.");
          }
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  