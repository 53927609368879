<template>
  <eden-confirm-dialog
    title="Use Post-paid"
    button-text="Submit"
    button-type="success"
    :show.sync="shouldShow"
    @confirm="fund"
  >
    <p class="head-text font-lg">Fund wallet through Post-paid</p>
    <p class="font-md mt-2">
      If you proceed, the wallet will be credited with a balance of
      <span class="text-bold">{{ formatPrice(localAmount) }}</span> and will be required to pay later.
    </p>
  </eden-confirm-dialog>
</template>
  
  <script>
import wallet from "@/requests/customers/corporate/wallet";

export default {
  name: "PostPaidConfirmation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localAmount: "",
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.localAmount = parseFloat(this.amount)
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    fund() {
      this.loading = true;
      const payload = {
        corporation_id: this.customerId,
        amount: this.localAmount,
        channel: "postpaid"
      };
      wallet
        .fundWallet(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("update-activity");
            this.loading = false;
            this.closeEvent();
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
  
  <style lang="scss"></style>
  