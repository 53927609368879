<template>
  <div>
    <el-dialog
      title="Enter Amount"
      :visible.sync="shouldShow"
      @close="closeEvent"
      append-to-body
      width="30%"
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Amount" prop="amount" :rules="validateField()">
              <el-input
                placeholder="Enter amount to be paid"
                type="text"
                v-number
                v-model.number="form.amount"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :disabled="!form.amount" @click="submitForm"
          >Continue</el-button
        >
      </span>
    </el-dialog>

    <post-paid-confirmation
      v-if="showConfirmation"
      :show.sync="showPostPaidConfirmation"
      :amount="amountPayment"
      @update-activity="updateActivity"
    />
  </div>
</template>

    <script>
import PostPaidConfirmation from "@/components/Customers/Corporate/Wallet/FundWallet/PostPaidConfirmation";

export default {
  name: "PostPaidPayment",
  components: {
    PostPaidConfirmation,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      linking: false,
      showConfirmation: false,
      showPostPaidConfirmation: false,
      form: {
        amount: 0,
      },
      amountPayment: 0,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    form: {
      handler(form) {
        this.amountPayment = parseFloat(form.amount);
      },
      deep: true,
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    submitForm() {
      if (this.$refs.form.validate()) {
        this.shouldShow = false;
        this.showConfirmation = true;
        this.showPostPaidConfirmation = true;
      }
    },
    updateActivity() {
      this.$emit("update-activity");
    }
  },
};
</script>
    
    <style lang="scss" scoped></style>
    