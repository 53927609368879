<template>
  <div>
    <el-dropdown @command="command" class="more">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <div class="customer-actions">
          <template v-for="(section, index) in computedActions">
            <div :key="index" class="section">
              <p v-if="showSectionTitle">{{ section.label }}</p>
              <template v-for="(action, i) in section.actions">
                <el-dropdown-item
                  v-if="allowAccessFor(action.access)"
                  :key="i"
                  :icon="action.icon"
                  :command="{
                    action: action.name,
                    component: action.component,
                  }"
                >
                  {{ action.label }}</el-dropdown-item
                >
              </template>
            </div>
          </template>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <component
      :is="action.component"
      :show.sync="action.visibility"
      :customer="customer"
      :email="customer.email"
      :generate-invoice="action.command === 'generate-invoice'"
      @success="emitUpdate"
      :all-customers="allCustomers"
      :channel="section === 'wallet' ? 'wallet' : 'card'"
    /> -->
  </div>
</template>

<script>
export default {
  name: "CustomerActions",
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    section: {
      type: String,
      default: "all",
    },
    allCustomers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  // components: {
  //   CustomerGardenerChange: () => import("./Account/CustomerGardenerChange"),
  //   CustomerStatusChange: () => import("./Account/CustomerStatusChange"),
  //   CustomerChatLink: () => import("./Account/CustomerChatLink"),
  //   CustomerEmailChange: () => import("./Account/CustomerEmailChange"),
  //   CustomerEmailVerification: () =>
  //     import("./Account/CustomerEmailVerification"),
  //   CustomerIbnaChange: () => import("./Account/CustomerIbnaChange"),
  //   CustomerPasswordReset: () => import("./Account/CustomerPasswordReset"),
  //   CustomerAccountDelete: () => import("./Account/CustomerAccountDelete"),
  //   CustomerMealRecommendations: () =>
  //     import("./Orders/CustomerMealRecommendations"),
  //   CustomerOrdersCreate: () => import("./Orders/CustomerOrdersCreate"),
  //   CustomerOrdersPause: () => import("./Orders/CustomerOrdersPause"),
  //   CustomerOrdersDelete: () => import("./Orders/CustomerOrdersDelete"),
  //   PaymentLinkForm: () =>
  //     import("@/components/Subscriptions/PaymentLinks/PaymentLinkForm"),
  //   CustomerServicesCharge: () => import("./Payment/CustomerServicesCharge"),
  //   TransactionForm: () =>
  //     import("@/components/Subscriptions/Transactions/TransactionForm"),
  //   SendMessageTemplate: () =>
  //     import("@/components/Gardeners/Messaging/SendMessageTemplate"),
  // },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          label: "Account",
          actions: [
            {
              access: ["admin", "gardener", "gardener_pro"],
              icon: "el-icon-plus",
              label: "Send A Message",
              name: "send-message",
              component: "send-message-template",
            },
            {
              access: ["admin", "gardener", "gardener_pro"],
              icon: "eden-icon-gardener",
              label: "Change gardener",
              name: "gardener-change",
              component: "customer-gardener-change",
            },
            {
              access: ["admin", "growth", "gardener", "gardener_pro"],
              icon: "eden-icon-edit",
              label: "Change status",
              name: "status-change",
              component: "customer-status-change",
            },
            {
              access: ["admin", "gardeners"],
              icon: "el-icon-chat-square",
              label: "Link chat",
              name: "chat-link",
              component: "customer-chat-link",
            },
            {
              access: ["superadmin", "gardener_pro"],
              icon: "eden-icon-edit",
              label: "Change email",
              name: "email-change",
              component: "customer-email-change",
            },
            {
              access: ["admin", "growth", "gardener", "gardener_pro"],
              icon: "el-icon-circle-check",
              label: "Verify customer",
              name: "verify",
              component: "customer-email-verification",
            },
            {
              access: ["admin", "gardener", "gardener_pro"],
              icon: "el-icon-open",
              label: "Change IBNA",
              name: "ibna",
              component: "customer-ibna-change",
            },
            {
              access: ["superadmin", "admin", "gardener", "gardener_pro"],
              icon: "eden-icon-settings",
              label: "Reset password",
              name: "password-reset",
              component: "customer-password-reset",
            },
            {
              access: ["superadmin"],
              icon: "eden-icon-delete",
              label: "Delete account",
              name: "delete",
              component: "customer-account-delete",
            },
          ],
        },
        {
          label: "Orders",
          actions: [
            {
              access: ["superadmin", "admin", "gardener", "gardener_pro"],
              icon: "el-icon-check",
              label: "Run recommendations",
              name: "recommendations",
              component: "customer-meal-recommendations",
            },
            {
              access: "all",
              icon: "eden-icon-orders",
              label: "Pause/Unpause orders",
              name: "pause-orders",
              component: "customer-orders-pause",
            },
            {
              access: ["superadmin"],
              icon: "eden-icon-add-circle",
              label: "Create orders",
              name: "create-orders",
              component: "customer-orders-create",
            },
            {
              access: ["superadmin"],
              icon: "eden-icon-delete",
              label: "Delete orders",
              name: "delete-orders",
              component: "customer-orders-delete",
            },
          ],
        },
        {
          label: "Payments",
          actions: [
            {
              access: "all",
              icon: "el-icon-link",
              label: "Generate Link",
              name: "payment-link",
              component: "payment-link-form",
            },
            {
              access: ["admin", "gardener", "gardener_pro"],
              icon: "eden-icon-subscriptions",
              label: "Charge customer",
              name: "charge",
              component: "customer-services-charge",
            },
            {
              access: ["admin", "gardener", "gardener_pro"],
              icon: "el-icon-document-checked",
              label: "Log transaction",
              name: "transaction",
              component: "transaction-form",
            },
            {
              access: ["admin", "gardener", "gardener_pro", "gardener_pro"],
              icon: "el-icon-plus",
              label: "Generate invoice",
              name: "generate-invoice",
              component: "transaction-form",
            },
          ],
        },
      ],
    };
  },
  computed: {
    computedActions() {
      switch (this.section) {
        case "account":
          return this.actions.slice(0, 1);
        case "orders":
          return this.actions.slice(1, 2);
        case "subscription":
          return this.actions.slice(2, 3);
        case "wallet":
          return this.actions.slice(2, 3).map((action) => ({
            ...action,
            actions: action.actions.slice(1, 2),
          }));
        default:
          return this.actions;
      }
    },
    showSectionTitle() {
      return this.computedActions.length !== 1;
    },
  },
  methods: {
    command(command) {
      this.action.command = command.action;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 200);
    },
    emitUpdate(data) {
      const { command } = this.action;
      const fetch = [
        "pause-orders",
        "create-orders",
        "delete-orders",
        "set-cycle",
        "delete",
      ];

      const { id } = this.$route.params;
      if (id && command === "delete") {
        return;
      }
      if (fetch.includes(command)) {
        this.$emit("fetch");
      } else {
        this.$emit("update", data);
      }
    },
  },
};
</script>

<style lang="scss">
.customer-actions {
  max-height: 200px;
  overflow-y: scroll;

  .section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      padding: 0 20px;
      margin: 10px 0;
      color: var(--eden-grey-primary);
      text-transform: uppercase;
      font-size: 0.825rem;
      font-weight: 600;
    }
  }
}
</style>
