<template>
  <el-dialog
    title="Reimburse wallet"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item label="Amount" prop="amount" :rules="validateField()">
            <el-input
              placeholder="Enter amount to be reimbursed"
              type="text"
              v-number
              v-model.number="form.amount"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item label="Reason" prop="reason" :rules="validateField()">
            <el-input
              placeholder="Enter reason"
              type="text"
              v-model="form.reason"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        :disabled="!form.reason || !form.amount"
        type="primary"
        :loading="loading"
        @click="reimburse"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>
    
    <script>
import wallet from "@/requests/customers/corporate/wallet";

export default {
  name: "ReimburseWallet",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        amount: null,
        reason: "",
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    reimburse() {
      this.loading = true;
      const payload = {
        corporation_id: this.customerId,
        amount: parseFloat(this.form.amount),
        channel: "reimbursement",
        reason: this.form.reason,
      };
      wallet
        .fundWallet(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.loading = false;
            this.closeEvent();
            this.$emit("update-activity");
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped></style>
    