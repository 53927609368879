<template>
  <el-dialog
    title="Fund wallet"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="40% !important"
  >
    <div class="dialog">
      <div class="text-bold">How would you like fund this wallet?</div>

      <el-row>
        <el-col :span="24">
          <eden-container
            v-for="(item, index) in options"
            :key="index"
            class="mt-2 text-cursor text-grey-primary mb-2 is-flex is-justify-between is-align-center"
            @click.native="command(item.command)"
          >
            <div class="is-flex is-justify-between is-align-center">
              <i :class="item.iconClass"></i>
            </div>
            <div class="is-flex is-justify-between is-align-center flex-d">
              <div>
                <span class="font-md text-medium">{{ item.title }}</span>
                <p class="text-grey-tertiary font-base">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <div><i class="el-icon-arrow-right text-bold"></i></div>
          </eden-container>
        </el-col>
      </el-row>
    </div>
    <payment-receipt-attachment-dialog
      :show.sync="showPaymentReceiptAttachmentDialog"
      @update-activity="updateWalletActivity"
    />
    <generate-payment-link :show.sync="showPaymentLink" />
    <post-paid-payment
      :show.sync="showPostPaidPaymentDialog"
      @update-activity="updateWalletActivity"
    />
    <reimburse-wallet
      :show.sync="showReimbursementWalletDialog"
      @update-activity="updateWalletActivity"
    />
  </el-dialog>
</template>

<script>
import PaymentReceiptAttachmentDialog from "@/components/Customers/Corporate/Wallet/FundWallet/PaymentReceiptAttachmentDialog.vue";
import GeneratePaymentLink from "@/components/Customers/Corporate/Wallet/FundWallet/GeneratePaymentLink.vue";
import ReimburseWallet from "@/components/Customers/Corporate/Wallet/FundWallet/ReimburseWallet.vue";
import PostPaidPayment from "@/components/Customers/Corporate/Wallet/FundWallet/PostPaidPayment.vue";

export default {
  name: "FundWalletOptionsDialog",
  components: {
    PaymentReceiptAttachmentDialog,
    GeneratePaymentLink,
    PostPaidPayment,
    ReimburseWallet,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      showPaymentReceiptAttachmentDialog: false,
      showPaymentLink: false,
      showPostPaidPaymentDialog: false,
      showReimbursementWalletDialog: false,
      options: [
        {
          iconClass: "el-icon-document",
          title: "Attach payment receipt",
          description:
            "Include the receipt and enter the amount for the wallet to be funded with.",
          command: "attach_receipt",
        },
        {
          iconClass: "el-icon-document",
          title: "Generate Payment link",
          description: "Get a link and pay through Pay stack.",
          command: "generate_link",
        },
        {
          iconClass: "el-icon-document",
          title: "Use Post-paid option",
          description: "Go below 0.00 and get funded with amount needed.",
          command: "use_postpaid",
        },
        {
          iconClass: "el-icon-circle-plus-outline",
          title: "Get Reimbursement",
          description: "Receive money returns from Eden.",
          command: "get_reimbursement",
        },
      ],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    command(command) {
      this.shouldShow = false;
      switch (command) {
        case "attach_receipt":
          this.showPaymentReceiptAttachmentDialog = true;
          break;
        case "generate_link":
          this.showPaymentLink = true;
          break;
        case "use_postpaid":
          this.showPostPaidPaymentDialog = true;
          break;
        case "get_reimbursement":
          this.showReimbursementWalletDialog = true;
          break;
        default:
          break;
      }
    },
    updateWalletActivity() {
      this.$emit("update-activity");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5;
}

.el-tag {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.dialog {
  .el-icon-document,
  .el-icon-circle-plus-outline {
    font-size: 29px;
    color: #4b6358;
  }

  .flex-d {
    flex: 0 0 80%;
  }
}
</style>
  